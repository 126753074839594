<template>
    <g>
        <path
            d="M12.96 14.84a7.26 7.26 0 100-14.53 7.26 7.26 0 000 14.53zM6.14 6.81c.35-.15.91-.18 1.4-.28.77-.15-.1-1.59-.07-2.15.04-.56 1.44-1.2 1.78-1.79.16-.3.01-.52-.18-.66a6.82 6.82 0 017.43-.23c-.3.18-.57.31-.65.35-.24.11-1-.57-1.65-.44-.64.13-1.97.44-1.87 1.16.11.72.88.38.8.76-.08.38-.63.55-1.02.6-.4.03-1.25.31-1.25.72 0 .4.02.7-.2.8-.23.08-.56.2-.45.5.11.32.63.41.82.2.18-.23.5-.35.88-.44a.5.5 0 01.62.3c.06.15.5.52.62.33.12-.18.04-.57 0-.66-.03-.1.28-.17.28-.17s.52.52.78.62c.26.1.88.1.93.4.05.32.4 1.84 1.09 2.19.68.35 1.46-1.06 1.82-1.02.37.04 1 1.62 1.33 1.31.14-.15.26-.33.35-.51A6.86 6.86 0 116.14 6.8z"
            fill="#111930"
        />
        <path
            d="M10.66 10.72c1.02-.1 1.27.17 1.46.73.2.55.22 1.68.51 1.97.29.28.73.1 1.14-.32.42-.43 1.92-2.46 1.78-2.77-.14-.3-.76-2.04-1.44-2.06-.69-.02-1 .29-1.4.05-.4-.24-1.37-.86-2.1-.64 0 0-1.25 1-1.25 1.64 0 .64.29 1.5 1.3 1.4zM3.56 22.51a3.37 3.37 0 01.33-.04 7.45 7.45 0 012.32.11 322.45 322.45 0 012.87.75c1.53.4 2.53.61 3.08.66 1 .08 2.37-.21 3.3-.57a53.4 53.4 0 002.3-1.07l.05-.03a430.46 430.46 0 002.08-1.01l.2-.1c.1-.05.29-.15.47-.32.46-.43.65-1.01.38-1.72a1.6 1.6 0 00-.9-.97 1.82 1.82 0 00-1.09-.08l-4.77 1.15a.31.31 0 10.15.6l4.77-1.14.08-.01c.2-.04.42-.02.63.06.24.1.43.29.55.6.17.46.06.78-.22 1.05a1.35 1.35 0 01-.32.22l-.2.1a447.78 447.78 0 01-2.08 1l-.06.03c-1.21.6-2.04.98-2.24 1.06a8.1 8.1 0 01-3.03.52 22.86 22.86 0 01-3.35-.73 314.58 314.58 0 00-2.84-.73 8.05 8.05 0 00-2.57 0 .31.31 0 10.1.61z"
            fill="#111930"
        />
        <path
            d="M3.38 17.8l.2-.04.52-.1a2080.58 2080.58 0 013.92-.83l.15-.03c.27-.05.44-.07.62-.06.12 0 .24.03.34.07.39.16 3.75 1.68 4.07 1.83.56.27.82.73.57 1.38-.22.57-.73.75-1.38.58a138.5 138.5 0 01-2.6-.77l-.34-.1-.14-.04a.31.31 0 00-.17.6l.13.04.35.1a1352.3 1352.3 0 002.61.77c.94.24 1.77-.05 2.12-.95.38-1-.05-1.77-.88-2.17-.33-.16-3.7-1.69-4.1-1.85-.18-.07-.36-.1-.56-.12-.22 0-.43.02-.74.07l-.16.03a210.14 210.14 0 00-3.94.83l-.52.11-.2.04a.31.31 0 10.13.61zM18.32 12.93A7.58 7.58 0 117.6 2.22a7.58 7.58 0 0110.7 10.71zm-.44-.44a6.95 6.95 0 10-9.83-9.83 6.95 6.95 0 009.83 9.83z"
            fill="#111930"
        />
    </g>
</template>
