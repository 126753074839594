<template>
    <g>
        <g clip-path="url(#clip0)">
            <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M19.07 6.49v-.11A6.55 6.55 0 0014.84.65 8.03 8.03 0 0012.06 0h-.22a8.9 8.9 0 00-2.45.43c-.89.33-1.66.76-2.22 1.3a6.55 6.55 0 00-2.34 4.65 7.6 7.6 0 001.23 4.97 17.8 17.8 0 011.55 2.7l.12.22c.1.43.33.97.33 1.62v.65c0 .43.22.87.44 1.08v3.03A3.42 3.42 0 0011.95 24c1.9 0 3.45-1.51 3.45-3.35V17.62c.22-.32.44-.65.44-1.08v-.65c0-.65.23-1.3.34-1.73l.11-.21c.11-.44 1.11-2.06 1.56-2.81a7.04 7.04 0 001.22-4.22v-.43zm-7.12 16.54a2.37 2.37 0 01-2.33-1.95h4.67a2.37 2.37 0 01-2.34 1.95zm-2.22-4.87h4.67V20H9.5v-1.84h.23zm8.34-11.13c0 1.08-.11 2.27-1.11 3.67-.45.65-1.45 2.38-1.67 3.03l-.11.22a4.74 4.74 0 00-.45 2.05v.65c0 .21-.22.54-.44.54h-1.9v-4.1a2.09 2.09 0 001.56-1.95c0-1.09-1-2.06-2.1-2.06-1.12 0-2.12.97-2.12 2.06a2 2 0 001.55 1.94v4.1H9.5c-.22-.1-.44-.32-.44-.53V16c0-.86-.22-1.51-.45-2.05v-.22c-.22-.65-1.33-2.38-1.66-3.03a6.17 6.17 0 01-1.12-4.32 6.06 6.06 0 012-4 6.1 6.1 0 011.9-1.08 7.36 7.36 0 012.11-.44h.22c.78 0 1.67.22 2.45.55.67.21 1.11.54 1.56.97a5.63 5.63 0 012 4v.65zm-6.12 5.08a1.1 1.1 0 01-1.11-1.08c0-.54.44-1.08 1.11-1.08.56 0 1.11.43 1.11 1.08 0 .54-.55 1.08-1.1 1.08z"
                fill="#111930"
            />
        </g>
        <defs>
            <clipPath id="clip0">
                <path
                    fill="#fff"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
    </g>
</template>
