<template>
    <div>
        <template v-if="type === INTERNAL">
            <NuxtLink
                :to="url"
                :target="target"
                :class="selectors"
            >
                <slot />
                <span class="button__icon">
                    <IconSelect
                        v-if="icon"
                        :icon="icon"
                    />
                </span>
            </NuxtLink>
        </template>

        <template v-else-if="type === EXTERNAL">
            <a
                :href="url"
                :target="target"
                rel="noopener noreferrer"
                :class="selectors"
            >
                <slot />
                <span class="button__icon">
                    <IconSelect
                        v-if="icon"
                        :icon="icon"
                    />
                </span>
            </a>
        </template>
    </div>
</template>

<script>
export const INTERNAL = 'internal';
export const EXTERNAL = 'external';

export default {
    props: {
        type: {
            type: String,
            validator: value => [INTERNAL, EXTERNAL].includes(value),
            default: INTERNAL
        },
        url: {
            type: [String, Object],
            required: true
        },
        target: {
            type: String,
            default: '_self'
        },
        classes: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            INTERNAL,
            EXTERNAL
        };
    },

    computed: {
        selectors() {
            return this.icon ? this.classes + ' button--icon-right' : this.classes;
        }
    }
};
</script>
