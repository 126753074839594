<template>
    <BoomerIcon><component :is="icon" /></BoomerIcon>
</template>

<script>
import IconThemeGreen from '~/components/_icons/theme-green.vue';
import IconThemeCreative from '~/components/_icons/theme-creative.vue';
import IconThemeSocial from '~/components/_icons/theme-social.vue';
import IconProgramCircularBusiness from '~/components/_icons/program-circular-business.vue';
import IconProgramCultureAndMedia from '~/components/_icons/program-culture-and-media.vue';
import IconProgramInnovativeLocalInitiatives from '~/components/_icons/program-innovative-local-initiatives.vue';
import IconProgramSocialManagementAndInclusion from '~/components/_icons/program-social-management-and-inclusion.vue';
import IconProgramSustainableEnergy from '~/components/_icons/program-sustainable-energy.vue';
import IconProgramSustainableFoodSystem from '~/components/_icons/program-sustainable-food-system.vue';
import IconProgramBankgiroLoterijFonds from '~/components/_icons/program-bankgiro-loterij-fonds.vue';
import IconChevronRightLarge from '~/components/_icons/chevron-right-large.vue';
import IconDownload from '~/components/_icons/download.vue';

export default {
    components: {
        IconThemeGreen,
        IconThemeCreative,
        IconThemeSocial,
        IconProgramCircularBusiness,
        IconProgramCultureAndMedia,
        IconProgramInnovativeLocalInitiatives,
        IconProgramSocialManagementAndInclusion,
        IconProgramSustainableEnergy,
        IconProgramSustainableFoodSystem,
        IconProgramBankgiroLoterijFonds,
        IconChevronRightLarge,
        IconDownload
    },
    props: {
        icon: {
            type: String,
            required: true,
            default: ''
        }
    }
};
</script>
