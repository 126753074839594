export const useDefaultVariables = () => {
    const { getCurrentCraftUri } = useCraft();
    const route = useRoute();
    const { activeSite } = useMultisite();
    const { locale } = useI18n();

    const variables = computed(() => {
        return {
            uri: decodeURI(getCurrentCraftUri(route.path, locale?.value)),
            siteId: activeSite.value
        };
    });

    return { variables };
};
