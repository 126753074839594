<template>
    <g>
        <g
            clip-path="url(#clip0)"
            fill="#111930"
        >
            <path d="M13.4 7c1.25-.14 2.87 0 4.56.4.94.22 1.86.52 2.75.9.93.4 1.66.84 2.13 1.25.35.3.51.56.5.6l-.72 3.05-.5 2.1-.22.96c-.77 3.25-4.44 6.11-7.32 5.43a4.09 4.09 0 01-.67-.22c-1.8-.78-3.14-2.87-3.5-5.25l-.66.1c.4 2.59 1.86 4.87 3.9 5.75.25.11.5.2.78.26 3.27.77 7.27-2.34 8.11-5.92l.23-.95.5-2.1c.44-1.88.7-3.03.72-3.06.15-.7-1.07-1.77-3.02-2.61a17.26 17.26 0 00-7.65-1.34l.07.65zM3.44 7.51a.33.33 0 01-.2.43c.1-.04.1-.04.22-.27l-.04-.21.02.05zm-.16-.19l.24.02c.27-.01.56-.09.85-.25.14-.07.28-.2.4-.37a2.81 2.81 0 00.32-.51.33.33 0 11.6.27c-.07.16-.2.4-.4.64-.17.24-.38.43-.6.55a2.54 2.54 0 01-1.51.3A1.05 1.05 0 013 7.94l-.1-.06c-.06-.06-.06-.06-.1-.29.11-.23.11-.23.22-.27a.33.33 0 01.24 0l.02.01zm-.02 0a.33.33 0 01.16.14.15.15 0 00-.05-.06l-.01-.01c-.07-.06-.07-.06-.1-.06l-.02-.01h.02zM8.66 5.88a.33.33 0 01-.42.2c.11.04.11.04.34-.07l.1-.18-.02.05zm0-.24l.17.16c.22.16.48.3.81.36.07.01.15.02.23.01a2.16 2.16 0 00.81-.2l.08-.03a.33.33 0 01.29.6 3.16 3.16 0 01-1.16.3 2.54 2.54 0 01-1.73-.76 1.04 1.04 0 01-.1-.16l-.04-.11c-.01-.1-.01-.1.1-.28.23-.1.23-.1.34-.07.08.03.15.09.18.16l.02.02zm.02.12v-.03l-.03-.1-.02-.03.01.02c.03.04.04.09.04.14zM10.2 11.87c0-.18.14-.33.33-.33-.16.02-.16.02-.32.23a.84.84 0 000 .17l-.01-.07zm-.05.1a3.18 3.18 0 00-2.38-.54c-.4.08-.84.35-1.28.76a5.88 5.88 0 00-.7.8.33.33 0 01-.55-.37 4.96 4.96 0 01.8-.9c.52-.5 1.07-.83 1.62-.93a3.83 3.83 0 013.12.86l.05.1c.03.05.03.05.01.22-.15.21-.15.21-.31.23a.33.33 0 01-.27-.14 1.44 1.44 0 00-.1-.08zm.11.1s.01 0 0 0l-.04-.07-.01-.04.05.1z" />
            <path d="M8.73 16.39c2.92-.52 5.16-4.6 4.58-7.9l-.17-.96-.37-2.12a6591 6591 0 01-.54-3.1c0-.03-.26-.2-.7-.34-.6-.2-1.44-.31-2.45-.31-.97 0-1.93.1-2.89.27a14 14 0 00-4.34 1.44c-.5.28-.83.54-1.03.78-.12.13-.16.23-.16.22l1.08 6.18c.53 2.98 3.48 5.9 6.29 5.9.23 0 .47-.02.7-.06zm.12.65c-.28.05-.55.07-.82.07-3.16 0-6.36-3.16-6.94-6.44L.01 4.49c-.08-.47.45-1.1 1.51-1.7C2.7 2.13 4.3 1.6 6.08 1.28c.99-.18 1.99-.27 3-.28 2.12 0 3.67.5 3.8 1.2l.54 3.1.37 2.12.17.97c.64 3.62-1.8 8.06-5.11 8.65z" />
            <path d="M15.03 12.08c.1-.15.3-.2.45-.09-.1-.06-.1-.06-.34-.01l-.14.15c0-.02.02-.03.03-.05zm-.06.24a1.88 1.88 0 00-.82-.73 1.46 1.46 0 00-.59-.1.33.33 0 11-.01-.67c.29 0 .58.05.86.16a2.55 2.55 0 011.15 1.04 1.03 1.03 0 01.06.17l.01.11c0 .1 0 .1-.17.25-.25.05-.25.05-.34-.01a.33.33 0 01-.14-.2l-.01-.02zm0-.12l.03-.07a.15.15 0 00-.02.07v.14a.33.33 0 010-.14zM18.06 13.24c-.13 0-.13 0-.3-.17-.04-.18-.04-.18-.02-.26l.06-.11a1 1 0 01.14-.13c.1-.06.2-.12.32-.17a2.56 2.56 0 011.28-.2c.28.03.57.14.86.3.2.12.36.24.46.32a.33.33 0 11-.42.5 1.7 1.7 0 00-1.04-.48 1.9 1.9 0 00-1.07.28h-.02a.33.33 0 01-.25.12zm.29-.49c-.17-.16-.17-.16-.3-.17a.33.33 0 01.34.35l-.04-.18zm-.04.38h-.02l.03-.01.05-.1v-.03a.33.33 0 01-.06.14zM13.73 16.99a.33.33 0 01-.44.15c.17.05.17.05.4-.09.05-.09.07-.12.07-.15l-.03.09zm.1-.09a2.92 2.92 0 001.88 1.44c.17.05.37.05.6.02.34-.04.7-.17 1.08-.34a5.97 5.97 0 00.67-.37.33.33 0 01.37.55 4.4 4.4 0 01-.76.42c-.43.2-.86.34-1.27.4-.3.04-.6.03-.86-.03a3.57 3.57 0 01-2.42-2.05l-.01-.1c0-.06 0-.06.07-.2.23-.15.23-.15.4-.1.1.05.16.14.18.24l.06.12zm-.07-.13a.3.3 0 010 .08.37.37 0 010 .03v-.1z" />
        </g>
        <defs>
            <clipPath id="clip0">
                <path
                    fill="#fff"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
    </g>
</template>
