<template>
    <g>
        <g
            clip-path="url(#clip0)"
            fill="#111930"
        >
            <path d="M16.27 7.6c0-1.74-1.39-3.16-3.1-3.16-1.7 0-3.09 1.42-3.09 3.16 0 1.75 1.4 3.17 3.1 3.17 1.7 0 3.1-1.42 3.1-3.17zm.67 0a3.8 3.8 0 01-3.76 3.83A3.8 3.8 0 019.42 7.6a3.8 3.8 0 013.76-3.83 3.8 3.8 0 013.76 3.83zM12.91.33v2a.33.33 0 00.67 0v-2a.33.33 0 00-.67 0zM16.48 1.18l-1 1.73a.33.33 0 10.57.33l1-1.73a.33.33 0 10-.57-.33zM19.21 3.68l-1.73 1a.33.33 0 10.33.57l1.74-1a.33.33 0 00-.34-.57zM6.08 7.87h2a.33.33 0 000-.66h-2a.33.33 0 000 .66zM6.93 4.3l1.73 1a.33.33 0 00.33-.57l-1.73-1a.33.33 0 00-.33.57zM9.42 1.56l1 1.73a.33.33 0 00.58-.33l-1-1.73a.33.33 0 10-.58.33zM13.59 14.8v-2a.33.33 0 00-.66 0v2a.33.33 0 00.66 0zM10.02 13.96l1-1.73a.33.33 0 00-.57-.34l-1 1.74a.33.33 0 10.57.33zM7.29 11.46l1.73-1a.33.33 0 00-.33-.57l-1.73 1a.33.33 0 00.33.57zM20.42 7.27h-2a.33.33 0 000 .66h2a.33.33 0 000-.66zM19.58 10.84l-1.74-1a.33.33 0 10-.33.57l1.73 1a.33.33 0 10.34-.57zM17.08 13.58l-1-1.74a.33.33 0 00-.57.34l1 1.73a.33.33 0 10.57-.33zM2.6 22.41a3.65 3.65 0 01.36-.05 7.94 7.94 0 012.47.12 345.65 345.65 0 013.07.8c1.63.42 2.7.66 3.29.7a9.24 9.24 0 003.52-.6c.24-.09 1.11-.5 2.45-1.15l.05-.02a454.86 454.86 0 002.43-1.19c.13-.05.32-.17.51-.34.5-.46.7-1.09.41-1.84a1.7 1.7 0 00-.97-1.04 1.94 1.94 0 00-1.15-.08l-5.1 1.22a.33.33 0 00.16.65l5.1-1.22a1.3 1.3 0 01.75.05c.26.1.46.3.6.65.17.48.06.83-.25 1.12a1.43 1.43 0 01-.34.23l-.22.1a530.33 530.33 0 01-2.21 1.09l-.06.03c-1.3.63-2.18 1.04-2.4 1.12-.91.35-2.3.64-3.23.57a24.38 24.38 0 01-3.58-.8 343.85 343.85 0 00-3.04-.77 8.58 8.58 0 00-2.74 0 .33.33 0 10.12.65z" />
            <path d="M2.4 17.38l.21-.05a4410.5 4410.5 0 014.75-1l.16-.03c.29-.05.47-.07.66-.06.13 0 .25.03.36.07.42.17 4.01 1.8 4.35 1.96.6.3.87.78.6 1.48-.23.61-.77.8-1.46.62a149.59 149.59 0 01-2.78-.82l-.37-.11-.14-.04a.33.33 0 10-.19.63l.14.04.37.11a1474.96 1474.96 0 002.8.83c1 .26 1.88-.05 2.25-1.02.41-1.06-.05-1.89-.94-2.32-.34-.16-3.95-1.8-4.38-1.97a1.75 1.75 0 00-.58-.12c-.25-.02-.47 0-.8.07l-.17.03a224.04 224.04 0 00-4.2.88l-.57.12-.2.05a.33.33 0 00.13.65z" />
        </g>
        <defs>
            <clipPath id="clip0">
                <path
                    fill="#fff"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
    </g>
</template>
