import { EXTERNAL, INTERNAL } from '~/components/NavLink/NavLink';
import { HOME_URI } from '~/enums/page-uri.js';

export default {
    methods: {
        mapType(type) {
            switch (type) {
            case 'hoofdnavigatie_linkinternal_BlockType':
            case 'linkBlock_linkInternal_BlockType':
            case 'projectLinkBlock_linkInternal_BlockType':
            case 'linkUrl_linkInternal_BlockType':
                return INTERNAL;
            case 'linkBlock_linkExternal_BlockType':
            case 'projectLinkBlock_linkExternal_BlockType':
            case 'linkUrl_linkExternal_BlockType':
            case 'hoofdnavigatie_externalLink_BlockType':
                return EXTERNAL;
            }
        },
        mapURI(link) {
            switch (this.mapType(link.__typename)) {
            case INTERNAL:
            {
                const uri = link?.linkInternal?.[0]?.uri ?? '',
                    url = this.fixHomeUri(uri);

                return this.localePath(`/${url}`);
            }
            case EXTERNAL:
                if (typeof link === 'string') {
                    if (link[0] === '/') {
                        // If route parameter is a path, create route object with path.
                        return this.localePath(link);
                    }
                }
                return link.linkExternal;
            }
        },
        mapTarget(isTargetBlank) {
            if (isTargetBlank) {
                return '_blank';
            }
            return '_self';
        },
        fixHomeUri(uri) {
            if (uri === HOME_URI) {
                return '/';
            }

            return uri;
        }
    }
};
